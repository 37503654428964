import hero from "@/assets/hero.png";
import { Hero } from "@/components/home/Hero/Hero";
import { Container } from "@/components/Shared/Container/Container";
import { MarkdownRenderer } from "@/components/Shared/MarkdownRenderer/MarkdownRenderer";
import { SectionHeader } from "@/components/Shared/SectionHeader/SectionHeader";
import { Box, Stack } from "@chakra-ui/layout";
import { chakra } from "@chakra-ui/system";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../../components/Shared/Layout/Layout";
import { SEO } from "../../components/Shared/SEO/SEO";

export const query = graphql`
  query useBlog($slug: String) {
    strapiBlog(slug: { eq: $slug }) {
      content
      slug
      title
      strapiId
      published_at
      category {
        name
        slug
      }
      author {
        email
        firstName
        lastName
      }
      description
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 90
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
`;

const SingleBlogPage = (props: any) => {
  const blog = props.data.strapiBlog;
  const finalImage = getImage(blog.image.localFile);

  return (
    <Layout>
      <SEO description={blog.description} />
      <Container maxW="container.lg">
        <Stack spacing={{ base: 4, md: 8 }} py={{ base: 10, md: 14 }}>
          <Box
            maxH={600}
            overflow="hidden"
            boxShadow="2xl"
            objectFit="cover"
            mt="2"
          >
            <GatsbyImage
              alt={`${blog.title}`}
              image={finalImage!}
              style={{ objectFit: "cover" }}
            />
          </Box>
          <Box>
            <SectionHeader title={blog.title} />
            {/* <Subheading fontWeight="600" fontSize="xl">
              {blog.description}
            </Subheading> */}
          </Box>
          <MarkdownRenderer>{blog.content}</MarkdownRenderer>
        </Stack>
      </Container>
      <Hero
        imageUrl={hero}
        title={"POZNANIE A PRÍPRAVA"}
        cta={{
          text: "Predpôrodné kurzy",
          href: "/#predporodne-kurzy"
        }}
        description={
          <>
            Pre rodičov je potrebné poznať a pripraviť sa na jednotlivé
            situácie, čo v konečnom <br className="hidden xl:flex" />
            dôsledku{" "}
            <chakra.span color="primary">
              výrazne napomáha úspešnému priebehu gravidity
            </chakra.span>
            , ako aj pôrodu.
          </>
        }
      />
    </Layout>
  );
};

export default SingleBlogPage;
